import React from "react";
import { Bell } from "lucide-react";
import { AlertPanelProps } from "../interfaces";



const AlertPanel: React.FC<AlertPanelProps> = ({
    show,
    onClose,
    alertTypes,
    selectedType,
    setSelectedType,
    alerts,
}) => {
    if (!show) return null;

    const filteredAlerts = alerts.filter((alert) =>
        selectedType === "All" ? true : alert.type === selectedType
    );

    return (
        <div className="fixed inset-0 z-50 flex">
            <div
                className="absolute inset-0 bg-black bg-opacity-40"
                onClick={onClose}
            />
            <div className="relative w-72 bg-white shadow-2xl ml-auto h-full transform transition-transform duration-300 ease-in-out">
                <div className="flex items-center justify-between px-3 py-3 bg-gray-50 text-white">
                    <div className="flex items-center space-x-2">
                        <Bell className="w-4 h-4 text-gray-800" />
                        <h2 className="text-sm font-semibold text-gray-800">Alerts</h2>
                    </div>
                    <button
                        onClick={onClose}
                        className="text-gray-600 hover:text-gray-800 text-sm"
                    >
                        ✕
                    </button>
                </div>

                <div className="px-3 py-2 border-b bg-gray-50">
                    <label
                        htmlFor="alertFilter"
                        className="block text-xs font-medium text-gray-700 mb-1"
                    >
                        Filter Alerts:
                    </label>
                    <select
                        id="alertFilter"
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                        className="w-full border border-gray-200 rounded px-2 py-1 text-xs 
                        focus:outline-none focus:ring-1 focus:ring-[#334155] 
                        hover:border-gray-300 transition"
                    >
                        {alertTypes.map((type) => (
                            <option key={type} value={type}>
                                {type}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="px-3 py-2 overflow-y-auto h-[calc(100%-100px)] bg-white">
                    {filteredAlerts.length === 0 ? (
                        <div className="text-xs text-gray-500 mt-2">No alerts found.</div>
                    ) : (
                        filteredAlerts.map((alert) => (
                            <div
                                key={alert.id}
                                className="border-b border-gray-100 py-3 last:border-none flex items-start"
                            >
                                <div
                                    className={`w-1.5 h-6 mr-2 rounded-r-sm mt-0.5 ${
                                        alert.type === "Order Executed"
                                            ? "bg-green-400"
                                            : alert.type === "Order Canceled"
                                            ? "bg-red-400"
                                            : alert.type === "Filter Applied"
                                            ? "bg-blue-400"
                                            : "bg-gray-300"
                                    }`}
                                />
                                <div className="flex-1">
                                    <div className="text-xs font-semibold text-gray-700">
                                        {alert.type}
                                    </div>
                                    <div className="text-xs text-gray-600">{alert.message}</div>
                                    <div className="text-[10px] text-gray-400 mt-1">
                                        {alert.timestamp}
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default AlertPanel;
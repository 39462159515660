import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "./Sidebar";
import AddStocksModal from "./AddStocksModal";
import BucketAddModal from "./BucketAddModal";
import AlertPanel from "./AlertPanel";
import PopupModal from "./PopupModel";
import PriceIndicator from "./PriceIndicator";
import ProfileMenu from "./ProfileMenu";
import TrendingStocksSection from "./TrendingStocks";
import { useBucketManager } from "../hooks/useBucketManager";
import { useStockSearch } from "../hooks/useStockSearch";
import { useAlertPanel } from "../hooks/useAlertPanel";
import { useDashboardState } from "../hooks/useDashboardState";
import { DhanStock } from "../interfaces";
import { UseWatchlistManager } from "../hooks/useWatchListManager";

export default function ProfessionalInterface() {
  const navigate = useNavigate();

  const [showAddStocks, setShowAddStocks] = useState(false);
  const [showAddToBucketPopup, setShowAddToBucketPopup] = useState(false);
  const [showAddBucketPopup, setShowAddBucketPopup] = useState(false);
  const [selectedStocks, setSelectedStocks] = useState<DhanStock[]>([]);
  const [selectedBucketId, setSelectedBucketId] = useState<string | null>(null);
  const [selectedBucketName, setSelectedBucketName] = useState<string | null>(null);
  const [showSettings, setShowSettings] = useState(false);
  const [expandedBucketId, setExpandedBucketId] = useState<string | null>(null);


  const {
    buckets,
    loadingBuckets,
    createBucket,
    addStocksToBucket,
    removeStockFromBucket,
    deleteBucket,
  } = useBucketManager();

  const {
    addedStocks,
    stocksData,
    loadingWatchlist,
    fetchWatchlist,
    addToWatchlist,
    removeFromWatchlist,
  } = UseWatchlistManager();

  const {
    searchQuery,
    filteredStocks,
    onSearchChange,
    setSearchQuery,
    setFilteredStocks,
  } = useStockSearch();

  const allAlerts = [
    { id: 1, type: "Order Executed", message: "Order #12345 was executed.", timestamp: "2025-03-10 10:30:00" },
    { id: 2, type: "Order Canceled", message: "Order #54321 was canceled.", timestamp: "2025-03-10 11:00:00" },
    { id: 3, type: "Filter Applied", message: "Applied filter: 'Nifty 50'.", timestamp: "2025-03-10 11:05:00" },
  ];

  const {
    showAlertPanel,
    openAlert,
    closeAlert,
    selectedType,
    setSelectedType,
    alertTypes,
    filteredAlerts,
  } = useAlertPanel(allAlerts);

  const {
    isRunning,
    setIsRunning,
    prices,
    recentOrders,
    pendingOrders,
    failedOrders,
  } = useDashboardState();

  const handleAddStocks = async () => {
    await addToWatchlist(selectedStocks);
    setSelectedStocks([]);
    setSearchQuery('');
    setShowAddStocks(false);
  };

  const handleAddToBucketStocks = async () => {
    if (!selectedBucketId) return;
    await addStocksToBucket(selectedBucketId, selectedStocks);
    setSelectedStocks([]);
    setSearchQuery('');
    setShowAddToBucketPopup(false);
  };

  const openAddToBucketPopup = (bucketId: string, bucketName: string) => {
    setSelectedBucketId(bucketId);
    setSelectedBucketName(bucketName);
    setShowAddToBucketPopup(true);
  };

  const handleStockSelection = (stock: DhanStock) => {
    setSelectedStocks((prev) =>
      prev.some((s) => s.security_id === stock.security_id)
        ? prev.filter((s) => s.security_id !== stock.security_id)
        : [...prev, stock]
    );
  };

  const handleShowAddStocks = () => {
    setShowAddStocks(true);
  };

  return (
    <div className="flex h-screen bg-gray-50">
     <Sidebar
  addedStocks={addedStocks}
  stocksData={stocksData}
  loadingAddStocks={loadingWatchlist}
  handleShowAddStocks={handleShowAddStocks}
  removeStock={removeFromWatchlist}
  recentOrders={recentOrders}
  pendingOrders={pendingOrders}
  failedOrders={failedOrders}
  setShowSettings={setShowSettings}
  buckets={buckets}
  loadingBuckets={loadingBuckets}
  onCreateBucket={createBucket}
  onAddToBucket={addStocksToBucket}
  onRemoveFromBucket={removeStockFromBucket}
  onDeleteBucket={deleteBucket}
  showAddBucketPopup={showAddBucketPopup}
  setShowAddBucketPopup={setShowAddBucketPopup}
  expandedBucketId={expandedBucketId}
  setExpandedBucketId={setExpandedBucketId}
  openAddToBucketPopup={openAddToBucketPopup}
/>

      {/* Main Content */}
      <div className="flex-1 h-full bg-white shadow">
        <div className="border-b px-6 py-3 flex items-center justify-between">
          <div className="flex space-x-4 overflow-auto">
            {prices.map((item) => {
              const change = item.currentPrice - item.previousPrice;
              const percentageChange = (change / item.previousPrice) * 100;
              return (
                <PriceIndicator
                  key={item.label}
                  label={item.label}
                  currentPrice={item.currentPrice}
                  priceChange={change}
                  percentageChange={percentageChange}
                />
              );
            })}
          </div>
          <ProfileMenu onLogout={() => console.log("Logging out")} />
        </div>

        <div className="flex flex-wrap gap-2 px-6 pt-4">
          <div className="flex-1 max-w-[45%]">
            <TrendingStocksSection />
          </div>
        </div>
      </div>

      {/* Floating Buttons */}
      <div className="fixed right-16 bottom-16 flex space-x-4">
        <button
          className="w-44 px-6 py-3 text-sm font-medium text-white rounded-lg shadow-lg bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 transition duration-300"
          onClick={() => navigate('/logic-builder')}
        >
          🔍 Logic Builder
        </button>

        <button
          className="w-44 px-6 py-3 text-sm font-medium text-white rounded-lg shadow-lg bg-gradient-to-r from-red-500 to-red-700 hover:from-red-600 hover:to-red-800 transition duration-300"
          onClick={openAlert}
        >
          🚨 Alert System
        </button>
      </div>

      {/* Popups */}
      <AlertPanel
        show={showAlertPanel}
        onClose={closeAlert}
        alerts={filteredAlerts}
        alertTypes={alertTypes}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
      />

      <AddStocksModal
        show={showAddStocks}
        onClose={() => setShowAddStocks(false)}
        searchQuery={searchQuery}
        filteredStocks={filteredStocks}
        selectedStocks={selectedStocks}
        onSearchChange={onSearchChange}
        onToggleStock={handleStockSelection}
        onAddStocks={handleAddStocks}
        mode="watchlist"
      />

      <AddStocksModal
        show={showAddToBucketPopup}
        onClose={() => setShowAddToBucketPopup(false)}
        searchQuery={searchQuery}
        filteredStocks={filteredStocks}
        selectedStocks={selectedStocks}
        onSearchChange={onSearchChange}
        onToggleStock={handleStockSelection}
        onAddStocks={handleAddToBucketStocks}
        mode="bucket"
        bucketName={selectedBucketName || ""}
      />

      <BucketAddModal
        show={showAddBucketPopup}
        onClose={() => setShowAddBucketPopup(false)}
        onCreate={createBucket}
      />

      {showSettings && (
        <PopupModal title="Settings" onClose={() => setShowSettings(false)}>
          <div className="space-y-4 text-sm text-gray-500">Settings content goes here.</div>
        </PopupModal>
      )}

      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
  );
}




// const handleGenerateRequestToken = async (url = "") => {
//     // Open the login URL in a new window
//     const token_url = await GenerateRequestTokenUrl()
//     console.log("TOKNE URL:", token_url)

//     if (!token_url) return;

//     const newWindow = window.open("http://localhost:3000/", "_blank", "width=600,height=400");
//     setWindowReference(newWindow);


//     window.addEventListener("message", (event) => {
//         try {
//             console.log("📩 Received message:", event);

//             // ✅ Ensure the message comes from the correct origin
//             if (event.origin !== "http://localhost:3000") return;

//             // Get the current URL from the event data
//             let currentURL = event.data.url;
//             console.log("🌍 Current URL:", currentURL);

//             console.log("🔴 Login not found initially, redirecting user...");
//             newWindow.location.href = token_url;

//             newWindow.postMessage({ action: "navigate" }, "http://localhost:3000"); // Ask the child to send its URL
//             setTimeout(() => {
//                 newWindow.location.href = "https://waliachandan.com/?action=login&type=login&status=success&request_token=jdb43RzZpEYgJXt4g4CBB4pOgH78Q7xA"
//             }, 5000)




//             if (currentURL.includes("request_token")) {
//                 console.log("🔄 request_token not detected, waiting for request token...");

//                 // Set an interval to keep checking the URL in the new window
//                 const checkRequestTokenComplete = setInterval(() => {
//                     try {
//                         newWindow.postMessage({ action: "request_token_check" }, "http://localhost:3000"); // Ask the child to send its URL
//                     } catch (err) {
//                         console.warn("⚠️ Cannot send message to new window.");
//                         clearInterval(checkRequestTokenComplete);
//                     }
//                 }, 3000); // Check every 2 seconds
//             }

//             // ✅ If "login" is in the URL, start monitoring until it disappears
//             if (currentURL.includes("login")) {
//                 console.log("🔄 Login detected, waiting for completion...");

//                 // Set an interval to keep checking the URL in the new window
//                 const checkLoginComplete = setInterval(() => {
//                     try {
//                         newWindow.postMessage({ action: "check_url" }, "http://localhost:3000"); // Ask the child to send its URL
//                     } catch (err) {
//                         console.warn("⚠️ Cannot send message to new window.");
//                         clearInterval(checkLoginComplete);
//                     }
//                 }, 2000); // Check every 2 seconds
//             }
//             // else {
//             //     console.log("🔴 Login not found initially, redirecting user...");
//             //     newWindow.location.href = token_url;

//             //     newWindow.postMessage({ action: "navigate" }, "http://localhost:3000"); // Ask the child to send its URL
//             //     setTimeout(() => {
//             //         newWindow.location.href = "https://waliachandan.com/?action=login&type=login&status=success&request_token=jdb43RzZpEYgJXt4g4CBB4pOgH78Q7xA"
//             //     }, 5000)

//             // // ✅ Monitor when "request_token" appears in the URL
//             // const checkRequestToken = setInterval(() => {
//             //     try {
//             //         let updatedURL = newWindow.location.href;
//             //         console.log("🔍 Checking updated URL:", updatedURL);

//             //         // Extract request_token from the URL
//             //         const urlParams = new URL(updatedURL).searchParams;
//             //         const requestToken = urlParams.get("request_token");

//             //         if (requestToken) {
//             //             console.log("✅ Request Token Found:", requestToken);
//             //             setRequestToken(requestToken)
//             //             // Send token to parent window (optional)
//             //             window.opener.postMessage({ request_token: requestToken }, "http://localhost:3000");

//             //             // Close the window after 2 seconds
//             //             setTimeout(() => {
//             //                 newWindow.close();
//             //                 console.log("🛑 Window closed after retrieving token.");
//             //             }, 2000);

//             //             clearInterval(checkRequestToken); // Stop checking
//             //         }
//             //     } catch (err) {
//             //         console.warn("⚠️ Cannot access window location due to cross-origin policy.");
//             //         clearInterval(checkRequestToken);
//             //     }
//             // }, 2000); // Check every 2 seconds
//             // }
//         } catch (error) {
//             console.warn("⚠️ Error processing message:", error);
//         }
//     }, false);
// };
import { useState, useMemo } from "react";

interface Alert {
  id: number;
  type: string;
  message: string;
  timestamp: string;
}

export const useAlertPanel = (initialAlerts: Alert[]) => {
  const [showAlertPanel, setShowAlertPanel] = useState(false);
  const [selectedType, setSelectedType] = useState("All");

  const openAlert = () => setShowAlertPanel(true);
  const closeAlert = () => setShowAlertPanel(false);

  const alertTypes = useMemo(() => {
    const types = Array.from(new Set(initialAlerts.map((a) => a.type)));
    return ["All", ...types];
  }, [initialAlerts]);

  const filteredAlerts = useMemo(() => {
    if (selectedType === "All") return initialAlerts;
    return initialAlerts.filter((alert) => alert.type === selectedType);
  }, [initialAlerts, selectedType]);

  return {
    showAlertPanel,
    openAlert,
    closeAlert,
    selectedType,
    setSelectedType,
    alertTypes,
    filteredAlerts,
  };
};

import React, { useState } from "react";
import { TrendingUp } from "lucide-react";
import { TrendingStock } from "../interfaces";

// UI Tabs and Filters
const TABS = ['Gainers', 'Losers', 'Movers by volume', '52 week low', '52 week high'];
const FILTERS = [
  'Nifty 50', 'Nifty 100', 'Nifty 200', 'Nifty 500',
  'Nifty Auto', 'Nifty IT', 'Nifty Energy', 'Nifty Pharma',
  'Nifty FMCG', 'Bank Nifty', 'Nifty Metal', 'Nifty Midcap', 'Nifty Smallcap'
];


// Mock data for preview/demo
const MOCK_DATA: TrendingStock[] = [
  {
    security_id: "101",
    symbol_name: "RELIANCE",
    underlying_symbol: "RELIANCE-EQ",
    instrument_type: "EQ",
    price: 2434.9,
    change: 12.5,
    percentage: 0.5,
    volume: "10.54M"
  },
  {
    security_id: "102",
    symbol_name: "TCS",
    underlying_symbol: "TCS-EQ",
    instrument_type: "EQ",
    price: 3083.75,
    change: -25.3,
    percentage: -0.81,
    volume: "4.23M"
  },
  {
    security_id: "103",
    symbol_name: "INFY",
    underlying_symbol: "INFY-EQ",
    instrument_type: "EQ",
    price: 1398.2,
    change: 8.1,
    percentage: 0.58,
    volume: "2.98M"
  },
  {
    security_id: "104",
    symbol_name: "ICICI BANK",
    underlying_symbol: "ICICIBANK-EQ",
    instrument_type: "EQ",
    price: 891.6,
    change: 4.5,
    percentage: 0.51,
    volume: "2.72M"
  },
  {
    security_id: "105",
    symbol_name: "HDFC BANK",
    underlying_symbol: "HDFCBANK-EQ",
    instrument_type: "EQ",
    price: 1590.9,
    change: -10.2,
    percentage: -0.64,
    volume: "3.51M"
  }
];

const TrendingStocksSection: React.FC = () => {
  const [selectedFilter, setSelectedFilter] = useState(FILTERS[0]);
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState(TABS[0]);

  const topFiveStocks = MOCK_DATA.slice(0, 5);
  const handleViewAll = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const renderStockRow = (stock: TrendingStock, key: string | number) => {
    const isUp = stock.change >= 0;
    const priceClass = isUp ? "text-green-600" : "text-red-600";
    const absChange = Math.abs(stock.change).toFixed(2);
    const absPercent = Math.abs(stock.percentage).toFixed(2);

    return (
      <tr key={key} className="border-b last:border-none hover:bg-gray-50">
        <td className="px-2 py-1">
          <div className="font-semibold text-gray-800">{stock.symbol_name}</div>
          <div className="text-xs text-gray-500">
            {stock.underlying_symbol} • {stock.instrument_type}
          </div>
          <div className="text-xs text-gray-500">Volume: {stock.volume}</div>
        </td>

        <td className="px-2 py-1 text-right">
          <div className="text-gray-700 font-medium">{stock.price.toFixed(2)}</div>
          <div className={`text-xs font-semibold ${priceClass}`}>
            {isUp ? "+" : "-"}
            {absChange} ({isUp ? "+" : "-"}
            {absPercent}%)
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div className="mt-6 px-6 w-full">
      <div className="bg-white border border-gray-200 shadow rounded-lg p-4">
        <div className="flex items-center space-x-2 mb-3">
          <h2 className="text-lg font-semibold text-gray-800">Trending Stocks</h2>
          <TrendingUp className="w-5 h-5 text-blue-500" />
        </div>

        {/* Filter Select */}
        <div className="flex items-center justify-between mb-3">
          <div>
            <label className="mr-2 text-sm font-medium text-gray-700">Select Index:</label>
            <select
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e.target.value)}
              className="text-sm border rounded-md px-2 py-1 focus:outline-none"
            >
              {FILTERS.map((f) => (
                <option key={f} value={f}>{f}</option>
              ))}
            </select>
          </div>
          <button
            onClick={handleViewAll}
            className="bg-emerald-400 text-white px-3 py-1.5 rounded text-sm"
          >
            View All
          </button>
        </div>

        {/* Tabs */}
        <div className="flex items-center space-x-4 border-b border-gray-200 mb-3">
          {TABS.map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`text-sm py-2 px-1 font-medium border-b-2 ${
                activeTab === tab
                  ? "border-blue-500 text-blue-600"
                  : "border-transparent text-gray-500 hover:text-gray-700"
              }`}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* Table Preview (Top 5) */}
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 text-sm">
            <tbody>
              {topFiveStocks.map((stock) => renderStockRow(stock, stock.security_id))}
            </tbody>
          </table>
        </div>
      </div>

      {/* View All Modal */}
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="absolute inset-0 bg-black bg-opacity-50" onClick={closeModal} />
          <div className="relative bg-white w-full max-w-4xl mx-auto rounded shadow-lg z-50">
            <div className="flex items-center justify-between px-4 py-3 border-b border-gray-200">
              <h3 className="font-semibold text-gray-800">All Trending Stocks</h3>
              <button onClick={closeModal} className="text-gray-400 hover:text-gray-500">✕</button>
            </div>

            <div className="p-4 max-h-[75vh] overflow-y-auto">
              <label className="mr-2 text-sm font-medium text-gray-700">
                Select Index:
              </label>
              <select
                value={selectedFilter}
                onChange={(e) => setSelectedFilter(e.target.value)}
                className="text-sm border rounded-md px-2 py-1 mr-6"
              >
                {FILTERS.map((f) => (
                  <option key={f} value={f}>{f}</option>
                ))}
              </select>

              <div className="flex items-center space-x-4 border-b border-gray-200 mt-3">
                {TABS.map((tab) => (
                  <button
                    key={tab}
                    onClick={() => setActiveTab(tab)}
                    className={`text-sm py-2 px-1 font-medium border-b-2 ${
                      activeTab === tab
                        ? "border-blue-500 text-blue-600"
                        : "border-transparent text-gray-500 hover:text-gray-700"
                    }`}
                  >
                    {tab}
                  </button>
                ))}
              </div>

              <table className="min-w-full divide-y divide-gray-200 text-sm mt-3">
                <tbody>
                  {MOCK_DATA.map((stock, idx) => renderStockRow(stock, `${stock.security_id}-${idx}`))}
                </tbody>
              </table>
            </div>

            <div className="flex justify-end px-4 py-3 border-t border-gray-200">
              <button
                onClick={closeModal}
                className="text-sm font-medium px-4 py-2 border rounded hover:bg-gray-100"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrendingStocksSection;

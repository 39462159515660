import React, { useState } from "react";
import { BucketAddModalProps } from "../interfaces";



const BucketAddModal: React.FC<BucketAddModalProps> = ({ show, onClose, onCreate }) => {
  const [bucketName, setBucketName] = useState("");

  const handleSubmit = () => {
    if (!bucketName.trim()) return;
    onCreate(bucketName.trim());
    setBucketName("");
    onClose();
  };

  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50">
      <div className="bg-white p-5 rounded-lg w-96 shadow-lg">
        <h2 className="text-md font-semibold mb-3">Create New Bucket</h2>
        <input
          type="text"
          value={bucketName}
          onChange={(e) => setBucketName(e.target.value)}
          className="w-full border border-gray-300 rounded px-3 py-2 text-sm focus:outline-none focus:ring focus:border-blue-400"
          placeholder="Enter bucket name"
        />
        <div className="flex justify-end space-x-2 mt-4">
          <button
            onClick={onClose}
            className="text-sm px-4 py-1.5 rounded bg-gray-200 hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="text-sm px-4 py-1.5 rounded bg-blue-600 text-white hover:bg-blue-700"
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default BucketAddModal;

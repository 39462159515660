import React, { useState } from "react";
import { Loader, Plus, Settings, X } from "lucide-react";
import { Bucket, ConfirmationModalProps, DhanStock, Order, SidebarProps } from "../interfaces";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Sidebar: React.FC<SidebarProps> = ({
  addedStocks,
  stocksData,
  loadingAddStocks,
  handleShowAddStocks,
  removeStock,
  recentOrders,
  pendingOrders,
  failedOrders,
  setShowSettings,
  buckets,
  loadingBuckets,
  onCreateBucket,
  onAddToBucket,
  onRemoveFromBucket,
  onDeleteBucket,
  showAddBucketPopup,
  setShowAddBucketPopup,
  expandedBucketId,
  setExpandedBucketId,
  openAddToBucketPopup,
}) => {
  const [bucketToDelete, setBucketToDelete] = useState<Bucket | null>(null);

  const handleDragEnd = (result: any, bucketId: string) => {
    if (!result.destination) return;
    const reordered = Array.from(
      buckets.find((b) => b.id === bucketId)?.stocks || []
    );
    const [moved] = reordered.splice(result.source.index, 1);
    reordered.splice(result.destination.index, 0, moved);
    // TODO: Call backend update for new order
  };

  const renderStockRow = (stock: DhanStock, onRemove?: () => void) => {
    const stockData = stocksData.find((s) => s.security_id === stock.security_id);
    const price = stockData?.price;
    const change = stockData?.change;
    const percentChange = stockData?.percent_change;
    const colorClass =
      change && change > 0
        ? "text-green-600"
        : change && change < 0
        ? "text-red-600"
        : "text-gray-600";

    return (
      <div
        key={stock.security_id}
        className="group flex items-center justify-between text-[11px] px-1 py-1 hover:bg-gray-50 rounded-md transition-all"
      >
        <div className="flex items-center w-2/5">
          <span className="font-medium text-gray-800 truncate">
            {stock.underlying_symbol}
          </span>
        </div>
        <div className="w-1/5 text-right">
          <span className={colorClass}>{price !== undefined ? price.toFixed(2) : "--"}</span>
        </div>
        <div className="w-1/5 text-right">
          <span className={colorClass}>
            {change !== undefined ? (change >= 0 ? "+" : "") + change.toFixed(2) : "--"}
          </span>
        </div>
        <div className="w-1/5 text-right flex items-center justify-end">
          <span className={colorClass}>
            {percentChange !== undefined
              ? (percentChange >= 0 ? "+" : "") + percentChange.toFixed(2) + "%"
              : "--"}
          </span>
          {onRemove && (
            <button
              className="ml-1 p-0.5 rounded-full opacity-0 group-hover:opacity-100 transition-opacity hover:bg-gray-200"
              onClick={onRemove}
            >
              <X className="h-3 w-3 text-gray-500" />
            </button>
          )}
        </div>
      </div>
    );
  };

  const renderOrderSection = (title: string, orders: Order[], colorClass: string) => (
    <section>
      <div className="text-sm font-bold text-gray-700 mb-1 flex justify-between items-center">
        <span>{title}</span>
      </div>
      <div className="max-h-28 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 space-y-1">
        {orders.length > 0 ? (
          orders.map((order) => (
            <div key={order.id} className="text-[11px] px-1 py-1 flex justify-between items-center bg-gray-50 rounded">
              <div className="flex flex-col">
                <span className="font-medium">{order.stock}</span>
                <span className="text-gray-400 text-[10px]">{order.time}</span>
              </div>
              <div className="text-right">
                <span className={`${colorClass} font-semibold`}>{order.quantity} Shares</span>
                <div className={`text-[10px] font-bold mt-1 ${order.orderType === "Buy" ? "text-green-600" : "text-red-600"}`}>
                  {order.orderType === "Buy" ? "BUY" : "SELL"}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-gray-400 italic text-xs">No {title.toLowerCase()}</div>
        )}
      </div>
    </section>
  );

  return (
    <div className="w-64 bg-white border-r">
      <nav className="p-3 space-y-6 text-xs">
        {/* Watchlist */}
        <section>
          <div className="flex justify-between items-center">
            <h2 className="text-sm font-bold text-gray-700">Watchlist</h2>
            <button
              onClick={handleShowAddStocks}
              disabled={loadingAddStocks}
              className="text-blue-600 hover:text-blue-800 text-xs flex items-center"
            >
              {loadingAddStocks ? (
                <Loader className="w-4 h-4 animate-spin" />
              ) : (
                <Plus className="w-4 h-4" />
              )}
            </button>
          </div>

          <div className="mt-2 max-h-48 overflow-y-auto divide-y divide-gray-200 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
            {addedStocks.length > 0 ? (
              <>
                <div className="flex items-center justify-between font-semibold text-gray-500 text-[11px] px-1 py-1">
                  <span className="w-2/5">Symbol</span>
                  <span className="w-1/5 text-right">Price</span>
                  <span className="w-1/5 text-right">Chg</span>
                  <span className="w-1/5 text-right">%</span>
                </div>
                {addedStocks.map((stock) =>
                  renderStockRow(stock, () => removeStock(stock.security_id))
                )}
              </>
            ) : (
              <div className="p-2 text-gray-400 italic">No stocks added yet</div>
            )}
          </div>
        </section>

        {/* Buckets */}
        <section>
          <div className="flex justify-between items-center">
            <h2 className="text-sm font-bold text-gray-700">Buckets</h2>
            <button
              onClick={() => setShowAddBucketPopup(true)}
              className="text-blue-600 hover:text-blue-800 text-xs"
            >
              <Plus className="w-4 h-4" />
            </button>
          </div>

          {loadingBuckets ? (
            <div className="text-gray-400 italic text-xs mt-2">Loading buckets...</div>
          ) : buckets.length === 0 ? (
            <div className="text-gray-400 italic text-xs mt-2">No buckets yet</div>
          ) : (
            <div className="mt-2 space-y-2 max-h-40 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
              {buckets.map((bucket) => (
                <div
                  key={bucket.id}
                  className="bg-white shadow-sm rounded-md px-2 py-1 max-h-28 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
                >
                  <div
                    className="flex justify-between items-center cursor-pointer py-1 border-b"
                    onClick={() =>
                      setExpandedBucketId(
                        expandedBucketId === bucket.id ? null : bucket.id
                      )
                    }
                  >
                    <span className="text-sm font-semibold text-gray-800 truncate">
                      {bucket.name}
                    </span>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          openAddToBucketPopup(bucket.id, bucket.name);
                        }}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        <Plus className="w-4 h-4" />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setBucketToDelete(bucket);
                        }}
                        className="text-red-500 hover:text-red-700"
                      >
                        <X className="w-4 h-4" />
                      </button>
                    </div>
                  </div>

                  {expandedBucketId === bucket.id && (
                    <div className="mt-2">
                      {bucket.stocks.length === 0 ? (
                        <div className="text-gray-400 italic text-xs mt-2">No stocks in this bucket</div>
                      ) : (
                        <DragDropContext
                          onDragEnd={(result) => handleDragEnd(result, bucket.id)}
                        >
                          <Droppable droppableId={bucket.id}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {bucket.stocks.map((stock, index) => (
                                  <Draggable draggableId={stock.security_id} index={index} key={stock.security_id}>
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        {renderStockRow(stock, () =>
                                          onRemoveFromBucket(bucket.id, stock.security_id)
                                        )}
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </section>

        {/* Orders */}
        {renderOrderSection("Recent Orders", recentOrders, "text-gray-600")}
        {renderOrderSection("Pending Orders", pendingOrders, "text-yellow-600")}
        {renderOrderSection("Failed Orders", failedOrders, "text-red-600")}

        {/* Settings */}
        <section>
          <button
            className="w-full flex items-center px-4 py-2 text-xs font-medium rounded-md hover:bg-gray-100"
            onClick={() => setShowSettings(true)}
          >
            <Settings className="mr-2 h-4 w-4" /> Settings
          </button>
        </section>

        {/* Delete Bucket Confirmation */}
        {bucketToDelete && (
          <ConfirmationModal
            title="Delete Bucket"
            message={`Are you sure you want to delete \"${bucketToDelete.name}\"?`}
            onCancel={() => setBucketToDelete(null)}
            onConfirm={() => {
              onDeleteBucket(bucketToDelete.id);
              setBucketToDelete(null);
            }}
          />
        )}
      </nav>
    </div>
  );
};

export default Sidebar;


const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  message,
  onCancel,
  onConfirm,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50">
      <div className="bg-white w-full max-w-sm p-6 rounded-lg shadow-lg">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
          <button onClick={onCancel}>
            <X className="w-5 h-5 text-gray-500 hover:text-gray-800" />
          </button>
        </div>
        <p className="text-sm text-gray-600 mb-6">{message}</p>
        <div className="flex justify-end space-x-3">
          <button
            className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800 hover:bg-gray-100 rounded"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 text-sm text-white bg-red-600 hover:bg-red-700 rounded"
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

import { useState } from "react";
import {
  useFloating,
  offset,
  flip,
  shift,
  autoUpdate,
  FloatingPortal,
} from "@floating-ui/react";

export const CustomTooltip = ({ children, content }) => {
  const [open, setOpen] = useState(false);

  const { refs, floatingStyles, update } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [offset(8), flip(), shift()],
    placement: "top",
    whileElementsMounted: autoUpdate,
  });

  return (
    <>
      <div
        ref={refs.setReference}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        className="inline-block"
      >
        {children}
      </div>

      {open && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className="z-[9999] px-3 py-2 text-xs bg-white text-gray-800 border border-gray-200 rounded-md shadow-xl max-w-[280px] max-h-60 overflow-auto whitespace-pre-wrap transition-opacity duration-150"
          >
            {content}
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

import React from "react";
import { ArrowUp, ArrowDown } from "lucide-react";
import { PriceIndicatorProps } from "../interfaces";



const PriceIndicator: React.FC<PriceIndicatorProps> = ({
    label,
    currentPrice,
    priceChange,
    percentageChange,
}) => {
    const isUp = priceChange >= 0;
    const absoluteChange = Math.abs(priceChange).toFixed(2);
    const absolutePercent = Math.abs(percentageChange).toFixed(2);

    return (
        <div className="flex flex-col bg-white shadow rounded-md px-3 py-2">
            {/* First line: Symbol name + small arrow */}
            <div className="flex items-center space-x-1">
                {isUp ? (
                    <ArrowUp className="w-3 h-3 text-green-500" />
                ) : (
                    <ArrowDown className="w-3 h-3 text-red-500" />
                )}
                <span className="text-sm font-bold text-gray-800">{label}</span>
            </div>

            {/* Second line: Price, Change, and Percent */}
            <div className="flex items-center space-x-2 mt-1">
                <span className="text-sm text-gray-600">{currentPrice.toFixed(2)}</span>
                <span
                    className={`text-xs font-medium ${
                        isUp ? 'text-green-600' : 'text-red-600'
                    }`}
                >
                    {isUp ? '+' : '-'}
                    {absoluteChange} ({isUp ? '+' : '-'}
                    {absolutePercent}%)
                </span>
            </div>
        </div>
    );
};

export default PriceIndicator;

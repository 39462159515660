import { useState, useCallback } from "react";
import { debounce } from "lodash";
import { DhanStock } from "../interfaces";
import { host } from "../../utilities/util";

export const useStockSearch = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredStocks, setFilteredStocks] = useState<DhanStock[]>([]);

  const fetchFilteredStocks = async (query: string) => {
    if (!query.trim()) {
      setFilteredStocks([]);
      return;
    }

    try {
      const res = await fetch(`${host}search-instruments-dhan?q=${query}`);
      const data = await res.json();
      setFilteredStocks(data || []);
    } catch (err) {
      console.error("🔍 Error fetching filtered stocks:", err);
    }
  };

  const debouncedFetchStocks = useCallback(
    debounce((query: string) => {
      fetchFilteredStocks(query);
    }, 400),
    []
  );

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedFetchStocks(value);
  };

  return {
    searchQuery,
    setSearchQuery,
    filteredStocks,
    setFilteredStocks,
    onSearchChange,
  };
};

import { useState, useEffect } from "react";
import axios from "axios";
import { host } from "../../utilities/util";
import { toast } from "react-toastify";
import { Bucket, DhanStock } from "../interfaces";


export const useBucketManager = () => {
  const [buckets, setBuckets] = useState<Bucket[]>([]);
  const [loadingBuckets, setLoadingBuckets] = useState(false);
  const fetchBuckets = async () => {
    setLoadingBuckets(true);
    try {
      const res = await axios.get(`${host}get-buckets`);
      const { status, message, data } = res.data;

      if (status === "success" && Array.isArray(data)) {
        const formatted = data.map((bucket: any) => ({
          id: bucket._id,
          name: bucket.name,
          stocks: bucket.stocks,
        }));
        setBuckets(formatted);
      } else {
        toast.error(`❌ Failed to load buckets: ${message || "Unknown error"}`);
        console.warn("Unexpected response:", res.data);
      }
    } catch (err) {
      toast.error("❌ Error loading buckets. Please try again.");
      console.error("API Error:", err);
    } finally {
      setLoadingBuckets(false);
    }
  };

  // ➕ Create new bucket
  const createBucket = async (name: string) => {
    try {
      const res = await axios.post(`${host}create-bucket`, { name });
      const { status, message, data } = res.data;

      if (status === "success" && data) {
        const newBucket = {
          id: data._id,
          name: data.name,
          stocks: [],
        };
        setBuckets((prev) => [...prev, newBucket]);
        toast.success("✅ Bucket created");
      } else {
        toast.error(`❌ Failed to create bucket: ${message || "Unknown error"}`);
        console.warn("Unexpected response:", res.data);
      }
    } catch (err: any) {
      const msg = err?.response?.data?.message || "Server error while creating bucket";
      toast.error(`❌ ${msg}`);
      console.error("Create bucket error:", err);
    }
  };

  // ➕ Add stocks to a bucket
  const addStocksToBucket = async (bucketId: string, stocks: DhanStock[]) => {
    try {
      const res = await axios.post(`${host}add-to-bucket/${bucketId}`, stocks);
      const { status, message } = res.data;

      if (status === "success") {
        toast.success(`✅ ${message}`);
        fetchBuckets(); // or update local state manually
      } else {
        toast.error(`❌ Failed to add stocks: ${message}`);
        console.warn("Unexpected response:", res.data);
      }
    } catch (err) {
      toast.error("❌ Failed to add stocks to bucket");
      console.error("Add stocks error:", err);
    }
  };

  // ❌ Remove stock from a bucket
  const removeStockFromBucket = async (bucketId: string, security_id: string) => {
    try {
      const res = await axios.delete(`${host}remove-stock-from-bucket/${bucketId}`, {
        data: { security_id },
      });
      const { status, message } = res.data;

      if (status === "success") {
        toast.success(`✅ ${message}`);
        fetchBuckets(); // or update local state manually
      } else {
        toast.error(`❌ Failed to remove stock: ${message}`);
        console.warn("Unexpected response:", res.data);
      }
    } catch (err) {
      toast.error("❌ Failed to remove stock");
      console.error("Remove stock error:", err);
    }
  };

  // ❌ Delete a bucket
  const deleteBucket = async (bucketId: string) => {
    try {
      const res = await axios.delete(`${host}delete-bucket/${bucketId}`);
      const { status, message } = res.data;

      if (status === "success") {
        toast.success("🗑️ Bucket deleted");
        setBuckets((prev) => prev.filter((b) => b.id !== bucketId));
      } else {
        toast.error(`❌ Failed to delete bucket: ${message}`);
        console.warn("Unexpected response:", res.data);
      }
    } catch (err) {
      toast.error("❌ Failed to delete bucket");
      console.error("Delete bucket error:", err);
    }
  };

  useEffect(() => {
    fetchBuckets();
  }, []);


  return {
    buckets,
    loadingBuckets,
    fetchBuckets,
    createBucket,
    addStocksToBucket,
    removeStockFromBucket,
    deleteBucket,
  };
};

import React from "react";
import { FixedSizeList as List, ListChildComponentProps } from "react-window";
import PopupModal from "./PopupModel";
import { AddStocksModalProps } from "../interfaces";

const AddStocksModal: React.FC<AddStocksModalProps> = ({
  show,
  onClose,
  searchQuery,
  filteredStocks,
  selectedStocks,
  onSearchChange,
  onToggleStock,
  onAddStocks,
  mode = "watchlist", // default mode
  bucketName,
}) => {
  if (!show) return null;

  const Row = ({ index, style }: ListChildComponentProps) => {
    const stock = filteredStocks[index];
    const isSelected = selectedStocks.some((s) => s.security_id === stock.security_id);

    return (
      <div
        key={stock.security_id}
        style={style}
        className={`flex items-center justify-between px-4 py-2 ${
          isSelected ? "bg-blue-50" : "hover:bg-gray-50"
        }`}
      >
        <div className="leading-tight">
          <div className="font-medium">{stock.symbol_name}</div>
          <div className="text-sm text-gray-500">
            {stock.underlying_symbol} {stock.instrument_type && `• ${stock.instrument_type}`}
          </div>
        </div>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => onToggleStock(stock)}
        />
      </div>
    );
  };

  const getModalTitle = () => {
    if (mode === "bucket") {
      return `Add Stocks to Bucket${bucketName ? `: ${bucketName}` : ""}`;
    }
    return "Add Stocks to Watchlist";
  };

  return (
    <PopupModal title={getModalTitle()} width="500px" onClose={onClose}>
      <input
        type="text"
        placeholder="Search stocks..."
        className="w-full p-2 border rounded"
        value={searchQuery}
        onChange={onSearchChange}
      />

      <div className="h-64 overflow-y-auto border mt-2">
        {filteredStocks.length > 0 ? (
          <List
            height={250}
            itemCount={filteredStocks.length}
            itemSize={50}
            width="100%"
          >
            {Row}
          </List>
        ) : (
          <div className="text-sm text-gray-400 text-center p-4">
            No results found.
          </div>
        )}
      </div>

      <div className="flex justify-end space-x-2 mt-4">
        <button
          className="px-4 py-2 text-sm bg-gray-100 rounded-md hover:bg-gray-200"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          className="px-4 py-2 text-sm bg-blue-600 text-white rounded-md hover:bg-blue-700"
          disabled={selectedStocks.length === 0}
          onClick={onAddStocks}
        >
          Add Selected ({selectedStocks.length})
        </button>
      </div>
    </PopupModal>
  );
};

export default AddStocksModal;

import { CustomTooltip } from "./CustomTooltip";

export const StockTag = ({ stock, isPassed }) => {
  const tooltipContent = (
    <div className="text-xs max-h-[240px] overflow-auto space-y-1 p-2">
      {Object.entries(stock.variables).map(([key, value]) => (
        <div key={key} className="flex justify-between gap-2">
          <span className="font-medium text-gray-600">{key}</span>
          <span className="text-right text-gray-800">{value}</span>
        </div>
      ))}
    </div>
  );

  const bgColor = isPassed
    ? "bg-green-100 hover:bg-green-200"
    : "bg-red-100 hover:bg-red-200";

  return (
    <CustomTooltip content={tooltipContent}>
      <div
        className={`px-3 py-1.5 ${bgColor} rounded-md text-xs text-center cursor-pointer transition-colors duration-150 ease-in-out`}
      >
        {stock.symbol}
      </div>
    </CustomTooltip>
  );
};

import { useState, useCallback, useEffect } from "react"; // ✅ Import useEffect
import axios from "axios";
import { toast } from "react-toastify";
import { DhanStock } from "../interfaces";
import { host } from "../../utilities/util";

export const UseWatchlistManager = () => {
  const [addedStocks, setAddedStocks] = useState<DhanStock[]>([]);
  const [stocksData, setStocksData] = useState<DhanStock[]>([]);
  const [loadingWatchlist, setLoadingWatchlist] = useState(false);

  const fetchWatchlist = useCallback(async () => {
    setLoadingWatchlist(true);
    toast.info("📥 Fetching watchlist...");

    try {
      const response = await axios.get(`${host}get-watchlist-dhan_format`);
      const watchlist: DhanStock[] = response.data || [];

      setAddedStocks(watchlist);
      setStocksData(watchlist);
      toast.success("✅ Watchlist loaded successfully!");
    } catch (error: any) {
      console.error("❌ Error fetching watchlist:", error.response?.data || error.message);
      toast.error("Failed to load watchlist.");
    } finally {
      setLoadingWatchlist(false);
    }
  }, []);

  const addToWatchlist = async (stocks: DhanStock[]) => {
    if (stocks.length === 0) {
      toast.warning("No stocks selected to add.");
      return;
    }

    toast.info("📤 Adding stocks to watchlist...");
    try {
      const response = await axios.post(`${host}add-to-watchlist-dhan_format`, stocks);

      if (response.status === 201) {
        setAddedStocks((prev) => {
          const all = [...prev, ...stocks];
          const unique = all.filter(
            (stock, index, self) =>
              index === self.findIndex((s) => s.security_id === stock.security_id)
          );
          return unique;
        });
        toast.success("✅ Stocks added to watchlist!");
      } else {
        toast.warning("⚠️ Unexpected response while adding stocks.");
      }
    } catch (error: any) {
      console.error("❌ Error adding to watchlist:", error.response?.data || error.message);
      toast.error("Failed to add stocks.");
    }
  };

  const removeFromWatchlist = async (securityId: string) => {
    try {
      const response = await axios.delete(`${host}remove-from-watchlist`, {
        data: [securityId],
        headers: { "Content-Type": "application/json" },
      });

      if (response.status === 200) {
        setAddedStocks((prev) => prev.filter((stock) => stock.security_id !== securityId));
        toast.success("✅ Stock removed from watchlist.");
      } else {
        toast.warning("⚠️ Failed to remove stock.");
      }
    } catch (error: any) {
      console.error("❌ Error removing stock:", error.response?.data || error.message);
      toast.error("Failed to remove stock.");
    }
  };

  // ✅ Fetch watchlist on first render
  useEffect(() => {
    fetchWatchlist();
  }, [fetchWatchlist]);

  return {
    addedStocks,
    stocksData,
    loadingWatchlist,
    fetchWatchlist,
    addToWatchlist,
    removeFromWatchlist,
  };
};

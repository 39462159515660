import { useState } from "react";

interface Order {
  id: number;
  orderId: string;
  stock: string;
  quantity: number;
  orderType: "Buy" | "Sell";
  time: string;
}

interface PriceItem {
  label: string;
  currentPrice: number;
  previousPrice: number;
}

export const useDashboardState = () => {
  const [isRunning, setIsRunning] = useState(false);

  const [prices, setPrices] = useState<PriceItem[]>([
    { label: 'Nifty 50', currentPrice: 17652.9, previousPrice: 17600.0 },
    { label: 'India VIX', currentPrice: 13.48, previousPrice: 14.5 },
    { label: 'US Dollar', currentPrice: 82.36, previousPrice: 82.50 },
    { label: 'BTC', currentPrice: 27890.12, previousPrice: 27700.12 },
    { label: 'GOLD', currentPrice: 89200, previousPrice: 87300 },
  ]);

  const [recentOrders, setRecentOrders] = useState<Order[]>([
    { id: 1, orderId: 'ORD12345', stock: 'AAPL', quantity: 10, orderType: 'Buy', time: '09:15 AM' },
    { id: 2, orderId: 'ORD12346', stock: 'GOOGL', quantity: 5, orderType: 'Sell', time: '09:32 AM' },
    { id: 3, orderId: 'ORD12347', stock: 'MSFT', quantity: 8, orderType: 'Buy', time: '09:45 AM' },
    { id: 4, orderId: 'ORD12348', stock: 'AMZN', quantity: 12, orderType: 'Sell', time: '10:01 AM' },
  ]);

  const [pendingOrders, setPendingOrders] = useState<Order[]>([
    { id: 5, orderId: 'ORD12349', stock: 'TSLA', quantity: 7, orderType: 'Buy', time: '10:14 AM' },
    { id: 6, orderId: 'ORD12350', stock: 'INFY', quantity: 15, orderType: 'Sell', time: '10:20 AM' },
  ]);

  const [failedOrders, setFailedOrders] = useState<Order[]>([
    { id: 7, orderId: 'ORD12351', stock: 'NFLX', quantity: 4, orderType: 'Buy', time: '10:28 AM' },
    { id: 8, orderId: 'ORD12352', stock: 'TCS', quantity: 9, orderType: 'Sell', time: '10:30 AM' },
  ]);

  return {
    isRunning,
    setIsRunning,
    prices,
    setPrices,
    recentOrders,
    pendingOrders,
    failedOrders,
  };
};
